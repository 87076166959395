$body-bg: #214035;
$body-color: #0f2d22;

$theme-colors: (
  "primary": #427aa3,
  "secondary": #beaa8d,
  "success": #499f69,
  "info": #c0bfbf,
  "warning": #fc9b4b,
  "danger": #f44336,
  "light": #214035,
  "dark": #0f2d22,
);

@import "node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (min-width: 600px) {
  .horizontal-dl {
    display: grid;
    grid-template-columns: max-content auto;

    dt {
      grid-column-start: 1;
      margin-right: 1em;
    }

    dd {
      grid-column-start: 2;
    }
  }
}

.horizontal-dl {
  dd a {
    color: $dark;
    :link {
      color: $dark;
    }
    :visited {
      color: $dark;
    }
  }
}

.ng-link {
  color: $dark;
  :link {
    color: $dark;
  }
  :visited {
    color: $dark;
  }
  text-decoration: none !important;
}

a {
  color: $dark;
  :link {
    color: $dark;
  }
  :visited {
    color: $dark;
  }
  text-decoration: none !important;
}
